import MaskedInput from "react-text-mask"
import PropTypes from 'prop-types'

const MaskData = (props) => {

	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			placeholder={'DD/MM/AA'}
			guide={false}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
		/>
	)
}

export default MaskData

MaskData.propTypes = {
	inputRef: PropTypes.func.isRequired
}