import { Button, withStyles } from "@material-ui/core";

const CustomButton = withStyles((theme) => ({
	root: {
		background: props => props.bg ? props.bg : '#b01010',
		color: props => props.fontcolor ? props.fontcolor : '#fff',

		'&:hover': {
			background: props => props.hover ? props.hover : props.bg ? props.bg : '#960d0d'
		},

		'&:disabled': {
			opacity: '.3',
			color: props => props.fontcolor ? props.fontcolor : '#fff',
		}
	}
}))(Button)

export default CustomButton