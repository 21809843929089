export function signInRequest(username, password) {
  return {
     type: '@auth/SIGN_IN_REQUEST',
     payload: { username, password }
  }
}

export function signInSuccess(token){
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token }
  }
}

export function signInFailure(){
  return {
    type: '@auth/SIGN_FAILURE',
  }
}

export function signOut(){
  return {
    type: '@auth/SIGN_OUT',
  }
}

export function tokenInvalid(){
  return {
    type: '@auth/TOKEN_INVALID',
  }
}
