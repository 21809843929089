import { makeStyles, createTheme } from "@material-ui/core";

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
});

const styleHome = makeStyles((theme) => ({
  wrapperHeader: {
    display: "flex",

    [responsive.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 16px",
    },
  },
  boxImageScanner: {
    width: 200,
    height: 200,
    marginRight: 10,

    "& img": {
      maxWidth: "100%",
    },

    [responsive.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },

  labelScanner: {
    fontFamily: "nunito-black",
    fontSize: 25,
    color: "#000",
  },

  labelTotal: {
    fontSize: 45,
    padding: "15px 10px",

    [responsive.breakpoints.down("sm")]: {
      fontSize: 25,
    },

    "&.hidden": {
      visibility: "hidden",
    },

    "& b.total": {
      color: "#2ecc71",
      fontSize: 60,
      position: "relative",
      padding: 10,
    },
  },

  boxOrders: {
    "&.hidden": {
      visibility: "hidden",
    },

    "& .order-added:first-child": {
      maxWidth: 700,
      padding: "20px 10px",

      "& span": {
        fontSize: 28,

        "&:last-child": {
          fontWeight: "bold",
        },
      },
    },

    "& .order-added:nth-child(2)": {
      maxWidth: 600,
      padding: "15px 10px",

      "& span": {
        fontSize: 22,
        "&:last-child": {
          fontWeight: "bold",
        },
      },
    },

    "& .order-added:nth-child(3)": {
      maxWidth: 500,
      padding: "10px 10px",

      "& span": {
        fontSize: 18,
        "&:last-child": {
          fontWeight: "bold",
        },
      },
    },

    "& .order-added": {
      display: "flex",
      justifyContent: "center",
      maxWidth: 400,
      padding: 5,
      borderRadius: 5,

      "&:nth-child(odd)": {
        background: "#f4f4f4",
      },

      "& span": {
        display: "block",
        padding: 5,

        "&:first-child": {
          minWidth: 140,
          textAlign: "center",
        },
      },
    },
  },

  btnEnvOrders: {
    padding: "20px 100px",
    margin: "15px 10px",
    fontSize: 22,
    fontWeight: "bold",
  },

  labelTopModal: {
    "& span": {
      fontFamily: "nunito",
      fontSize: 30,
    },

    "& span:last-child": {
      color: "#2ecc71",
      paddingLeft: 10,
      fontWeight: "bold",
    },
  },

  labelMiddleModal: {
    fontSize: 30,
    padding: "20px 0",
  },

  btnEnvOrdersModal: {
    fontSize: 22,
    margin: "10px 0",
  },

  btnMoreOrders: {
    fontSize: 14,
    margin: "10px 0",
  },

  boxProgress: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "45%",
    left: "50%",

    [responsive.breakpoints.down("sm")]: {
      top: "50%",
      left: "38%",
    },

    "& .MuiCircularProgress-root": {
      width: "70px !important",
      height: "70px !important",
      color: "#2ecc71",
    },

    "& .MuiTypography-root": {
      marginTop: 10,
      fontSize: 20,
      fontFamily: "nunito",
    },
  },
}));

export default styleHome;
