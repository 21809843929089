import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { signInFailure, signInSuccess } from './actions';

import apiEmbalei from '../../../services/api';
import { toast } from 'react-toastify';

export function* signIn({ payload }) {
  try {
    const { username, password } = payload;
    const res = yield call(apiEmbalei.post, 'session', {
      username,
      password,
    });
    const { token } = res.data
    if (token) {
      apiEmbalei.defaults.headers.Authorization = `Bearer ${token}`
      yield put(signInSuccess(token));
      yield put(push('/pedido'))
      toast.success('Seja bem-vindo(a)')
    }
  } catch (err) {
    yield put(signInFailure())
    toast.warn('Data de Nascimento inválida.')
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    apiEmbalei.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function* signOut() {
  yield put(push('/'))
}

export function* tokenInvalid() {
  yield put(push('/'))
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/TOKEN_INVALID', tokenInvalid),
]);
