import { css } from 'styled-components'
import NunitoRegular from './fonts/nunito/Nunito-Regular.ttf'
import NunitoBlack from './fonts/nunito/Nunito-Black.ttf'

export const MainFonts = css`
	@font-face{
		font-family: 'nunito';
    font-weight: 400;
    src: url('${NunitoRegular}');
	}

	@font-face{
	font-family: 'nunito-Black';
    font-weight: 900;
    src: url('${NunitoBlack}');
	}
`;