import { Route, Switch } from "react-router"
import PrivateRoute from "./PrivateRoute"

/*** Application Pages ***/
import Login from '../pages/public/Login'
import OrderSuccess from "../pages/private/OrderSuccess"
import Orders from "../pages/private/Orders"

const Routes = () => {
	return(
		<Switch>
			{/* Rotas Públicas */}
			<Route exact path="/" component={Login}/>

			{/* Rotas Privadas */}
			<PrivateRoute exact path="/pedido" component={Orders}/>
			<PrivateRoute exact path="/pedido/sucesso" component={OrderSuccess}/>
		</Switch>
	)
}

export default Routes