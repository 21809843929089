import { makeStyles } from "@material-ui/core";

const stylePageSuccess = makeStyles((theme) => ({
	root: {

	},

	labelSuccess: {
		paddingTop: 150,
		fontFamily: 'nunito',
		color: '#2ecc71',
		fontSize: 40,

		'& span': {
			fontWeight: 'bold',
		}
	},

	labelTimer: {
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left:0,
		marginBottom: 20,
		'& .MuiTypography-root': {
			fontFamily: 'nunito',
			fontSize: 30,
		}
	}
}))

export default stylePageSuccess