import { createGlobalStyle, keyframes } from "styled-components";
import { MainFonts } from "./fonts";

const fadeIn = keyframes`
	0% {
		top: -10px;
		opacity: 0;
  }

	95%{
		top: -70px;
		opacity: 1;
	}

  100% {
		opacity: 0;
  }
`;

const GlobalStyle = createGlobalStyle`
	${MainFonts}

	* {
		font-family: 'nunito', sans-serif;
	}

	body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.total span#plus{
		position: absolute;
		opacity: 0;
		top: -25px;
		left: 0;
		font-size: 50px;
		animation: 1.5s ${fadeIn} ease-out
	}

	#embalei {
		width: 100%;
	}
`;

export default GlobalStyle;
