import {
  Box,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";

import apiEmbalei from "../../../services/api";

import * as Actions from "../../../store/modules/auth/actions";
import { connect, useDispatch } from "react-redux";
import CustomInput from "../../../components/custom/CustomInput";

import stylePageLogin from "./style";
import CustomButton from "../../../components/custom/CustomButton";

import logo from "../../../assets/images/logo-embalei.png";

import MaskData from "../../../components/mask/MaskData";

import helper_validate_form from "../../../helpers/validation/form";
import { createGlobalStyle } from "styled-components";
import { useCallback } from "react";

const StyleSelect = createGlobalStyle`
	.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: 2px solid;
		border-top: none;
		height: 220px;
		margin-top: 6px;
		background: #fff
	}
`;

const Login = ({ signInRequest }) => {
  const style = stylePageLogin();

  const dispatch = useDispatch();

  const [signIn, setSignIn] = useState({
    username: "",
    password: "",
  });

  const [form, setForm] = useState({
    form: false,
    password: null,
    username: null,
  });

  const [users, setUsers] = useState([]);
  //   "afonso",
  //   "aimaas",
  //   "alan",
  //   "alesandro felix",
  //   "alisson oliveira",
  //   "ana clara",
  //   "arivaldo",
  //   "avanda",
  //   "ayslan",
  //   "beatriz santos",
  //   "bruna souza",
  //   "bruno souza",
  //   "cecilia",
  //   "regina sales",
  //   "danilo",
  //   "denis",
  //   "eduardo nery",
  //   "elaine souza",
  //   "elisangela",
  //   "enzo alves",
  //   "fabio feitosa",
  //   "fabricio",
  //   "fernanda souza",
  //   "gabriel almeida",
  //   "guilherme reis",
  //   "helio",
  //   "ingrid cristina",
  //   "ingrid grasiele",
  //   "isaias pereira",
  //   "jaiane",
  //   "jeferson oliveira",
  //   "jeffersion severino",
  //   "johnny maycow",
  //   "julio cesar",
  //   "julya",
  //   "karoline",
  //   "kayque",
  //   "laryssa",
  //   "leonardo delfino",
  //   "maickson douglas",
  //   "marcos silva",
  //   "mateus ventura",
  //   "mayara soares",
  //   "mirela",
  //   "natally silva",
  //   "rafael marques",
  //   "richard",
  //   "rodrigo arroyo",
  //   "rodrigo martins",
  //   "rodrigo viana",
  //   "sidcley",
  //   "thiago borges",
  //   "vanessa",
  //   "victor pereira",
  //   "vitor augusto",
  //   "vynicius",
  // ];

  const validationForm = useCallback(async () => {
    setForm(await helper_validate_form.login(signIn));
  }, [signIn]);

  const requestSignIn = (e) => {
    e.preventDefault();

    if (form.form) {
      dispatch(
        signInRequest(signIn.username, signIn.password.replace(/[^0-9]/g, ""))
      );
    }
  };

  useEffect(() => {
    validationForm();
  }, [signIn, validationForm]);

  useEffect(() => {
    apiEmbalei.get("/user").then((response) => {
      setUsers(response.data);
    });
  }, []);

  return (
    <>
      <StyleSelect />
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
        className={style.root}
      >
        <Box className={style.boxLogin}>
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            className={style.boxImage}
          >
            <img src={logo} alt="" />
          </Box>
          <form onSubmit={requestSignIn} data-form="login">
            <CustomInput
              variant="outlined"
              fullWidth
              id="input-user"
              className={`${
                form.username !== null
                  ? form.username
                    ? "is_valid"
                    : "invalid"
                  : ""
              } `}
            >
              <InputLabel htmlFor="select-user">Selecione seu nome</InputLabel>
              <Select
                required
                id="select-user-"
                value={signIn.username}
                onChange={(e) =>
                  setSignIn({ ...signIn, username: e.target.value })
                }
                label="Selecione seu nome"
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.username}>
                    {user.username.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </CustomInput>

            <CustomInput
              variant="outlined"
              id="input-date"
              fullWidth
              className={`${
                form.password !== null
                  ? form.password
                    ? "is_valid"
                    : "invalid"
                  : ""
              } `}
            >
              <InputLabel>Digite sua data de nascimento</InputLabel>
              <OutlinedInput
                required
                type="tel"
                value={signIn.password}
                label="Digite sua data de nascimento"
                inputComponent={MaskData}
                onChange={(e) =>
                  setSignIn({ ...signIn, password: e.target.value })
                }
              />
            </CustomInput>

            <CustomButton
              disabled={!form.form}
              type="submit"
              bg="#2ecc71"
              fullWidth
              size="large"
              className={style.btnLogin}
            >
              Entrar
            </CustomButton>
          </form>
        </Box>
      </Box>
      <Box position="fixed" className={style.imageBottomLogin}></Box>
    </>
  );
};

export default connect(null, Actions)(Login);
