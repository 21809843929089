import {
  Box,
  CircularProgress,
  Container,
  OutlinedInput,
  Typography,
} from "@material-ui/core";

import { useEffect, useState } from "react";

import leitor from "../../../assets/images/leitor.png";

import CustomInput from "../../../components/custom/CustomInput";
import CustomButton from "../../../components/custom/CustomButton";
import styleHome from "./style";
import apiEmbalei from "../../../services/api";
import { ExitToApp } from "@material-ui/icons";

import * as Actions from "../../../store/modules/auth/actions";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Orders = ({ signOut }) => {
  const dispatch = useDispatch();

  const style = styleHome();

  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);

  const [plus, setPlus] = useState({
    pontos: 0,
    totalItems: 0,
  });

  const [orders] = useState({
    success: false,
    total: 0,
    packages: [],
  });

 const handleOrder = async (e) => {
    const isEnterClick = e.keyCode === 13;

    if (isEnterClick) {
      const { value } = e.target;

      if (value) {
        try {
          const response = await apiEmbalei.get(`/chavenfe/${value}`);
          if (!response.data) {
            toast.warn("Código de pedido inválido.");
            return;
          }

          const orderId = response.data;

          const { data } = await apiEmbalei.get(`/orders/${orderId}`);

          if (!!data.length) {
            toast.warn("Esse pedido ja foi adicionado.");
            return;
          }

          const { packages } = orders;

          const isSameOrder = packages.some((o) => o.order === orderId);

          if (!!isSameOrder) {
            toast.error("Esse pedido já foi embalado.");
            return;
          }

          const res = await apiEmbalei.post("orders", {
            orders: [
              {
                order: orderId,
                beep_date: new Date().toLocaleString(),
              },
            ],
          });

          if (res.data.message === "Saving orders") {
            setPlus({
              pontos: res.data.totals.total_orders,
              totalItems: res.data.totals.total_items,
            });
            setloading(false);
            setOpen(false);
          }

          const input = document.querySelector("#input_order");
          input.value = "";
          input.focus();
        } catch (error) {
          toast.error(
            "Falha ao adicionar o pedido. Entre em contato com a Equipe SantoLabs^.",
            {
              autoClose: 5000,
            }
          );
        }
      }
    }
  };

  const exitToApp = () => {
    dispatch(signOut());
  };

  useEffect(() => {
    if (!open) {
      const input = document.querySelector("#input_order");
      input.value = "";
      input.focus();
    }
  }, [open]);

  return (
    <>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="flex-end">
          <CustomButton
            style={{ padding: "15px 20px", margin: "10px 0" }}
            bg="#f36969"
            hover="#d81717"
            onClick={exitToApp}
            startIcon={<ExitToApp />}
          >
            Sair
          </CustomButton>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={style.wrapperHeader}
        >
          <Box className={style.boxImageScanner}>
            <img src={leitor} alt="" />
          </Box>
          <Typography className={style.labelScanner}>
            POR FAVOR, ESCANEI O CUPOM DO PEDIDO NO LEITOR
          </Typography>
        </Box>

        <CustomInput variant="outlined" fullWidth className={style.inputOrder}>
          <OutlinedInput
            id="input_order"
            onKeyDown={handleOrder}
            autoFocus
            autoComplete="off"
            placeholder="Escanei seu pedido embalado aqui!"
          />
        </CustomInput>

        <Typography
          align="center"
          className={`${style.labelTotal} ${loading && "hidden"}`}
        >
          <b>Total:</b>
          <b className="total" id="total">
            {plus.totalItems}
          </b>
          <b>produtos embalados</b>
        </Typography>
        <Typography
          align="center"
          className={`${style.labelTotal} ${loading && "hidden"}`}
        >
          <b>Total:</b>
          <b className="total" id="total">
            <span>{plus.pontos}</span>
          </b>
          <b>pedidos confirmados</b>
        </Typography>
      </Container>
      {loading && (
        <Box className={style.boxProgress}>
          <CircularProgress />
          <Typography>Aguarde...</Typography>
        </Box>
      )}
    </>
  );
};

export default connect(null, Actions)(Orders);
