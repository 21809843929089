import { Redirect, Route } from "react-router";

import { store } from '../store'


const PrivateRoute = ({ component: Component, ...rest }) => {

	const token = store.getState().auth.token

	return (
		<Route {...rest} render={props => (
			token ?
				<Component {...props} />
				: <Redirect to="/" />
		)} />
	);
};

export default PrivateRoute