import { makeStyles } from "@material-ui/core";

import esteira from '../../../assets/images/embalei-esteira.gif'

const stylePageLogin = makeStyles((theme) => ({
	root: {
		width: '100vw',
		height: '80vh',
		background: '#f4f4f4',

		[theme.breakpoints.down('xs')]: {
			alignItems: 'center'
		}
	},

	boxLogin: {
		width: 423,
		height: 452,
		padding: '0 43px',
		background: '#fff',
		marginTop: 100,
		borderRadius: 10,

		[theme.breakpoints.down('xs')]: {
			width: 305,
			height: 320,
			position: 'relative',
			bottom: '15vw',

		}
	},

	boxImage: {
		height: 130,
		'& img': {
			position: 'relative',
			bottom: 60,
			maxWidth: '100%',
			height: 'max-content',

			[theme.breakpoints.down('xs')]: {
				maxWidth: '100%',
				height: 100,
			}
		},

		[theme.breakpoints.down('xs')]: {
			height: 60,
		}
	},

	imageBottomLogin: {
		backgroundImage: `url(${esteira})`,
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: 'center',
		bottom: 0,
		width: '100%',
		height: 280,

		[theme.breakpoints.down('xs')]: {
			height: 150,
			backgroundSize: 'cover'
		}
	},

	btnLogin: {
		height: 57,
		fontSize: 24,
		fontWeight: 'bold',
		marginTop: 30,

		[theme.breakpoints.down('xs')]: {
			marginTop: 20,
		}
	}
}))

export default stylePageLogin